import React from 'react';
import { useSelector } from 'react-redux';
import { selectGeneralData, selectCrew, selectPortCallData } from '../../../slices/portCallSlice';
import { DOCUMENT_CATEGORIES, DOCUMENT_TYPE_IDS } from "../../documents/constants";
import DefaultCrewTable from './DefaultCrewTable';
import {isDateBetween} from "../../../../global/utils/helpers";

function ArrivalCrewTable(props) {
  const generalPortcallData = useSelector(selectGeneralData);
  const portcallData = useSelector(selectPortCallData);
  const portcallEta = portcallData?.data?.eta?.estimatedTime
  const portcallEtd = portcallData?.data?.etd?.estimatedTime
  const portUnlocode = generalPortcallData?.unlocode;
  const crew = useSelector(selectCrew);
  const crewList = crew.crewList.map((crewMember, index) => { return { ...crewMember, index } });
  const arrivalCrewList = crewList.filter((crewMember) =>
  {
    if (crewMember.portOfEmbarkation !== portUnlocode){
      return true;
    } else {
      return !isDateBetween(crewMember.embarkationDatetime, portcallEta, portcallEtd)
    }
  }
);
  return (
    <DefaultCrewTable
      title={'Arrival'}
      crewList={crewList}
      sectionCrewList={arrivalCrewList}
      documentCategory={DOCUMENT_CATEGORIES.PRE_ARRIVAL_DOCUMENTS}
      documentTypeId={DOCUMENT_TYPE_IDS.UPLOAD_ARRIVAL_CREW}
      crewListDocumentLink={crew.crewListDocumentLink}
    />
  );
}

export default ArrivalCrewTable;
