import SummaryDocument from './SummaryDocument';
import { Flex } from '@navozyme/uikit/dist/atoms/Layout';
import { Text } from '@navozyme/uikit/dist/atoms/Typography';
import '../styles/summary.scss';

export const PortInstructionItem = ({ title, subtitle, documents, instructionsText, emptyDocumentsMessage }) => {
  const getDocumentUuid = (document) => document?.documentUid || document?.templateUid;

  return (
    <div className='summary-documents-block'>
      {title && <h3 className={`document-type-title document-type-title_with-title-button`}>{title}</h3>}
      {subtitle && <p className='page-subtitle'>{subtitle}</p>}
      {instructionsText && (
        <Flex
          borderRadius={2}
          borderWidth={1}
          borderColor='grey300'
          borderStyle='solid'
          backgroundColor='grey100'
          paddingY={2}
          paddingX={3}
          marginTop={3}
          alignItems='center'>
          <Text sizeText='display16' fontWeight='regular' color='grey500'>
            {instructionsText}
          </Text>
        </Flex>
      )}
      <div
        style={{
          padding: '10px',
        }}></div>
      {documents && documents.length > 0 ? (
        <div className='documents-block'>
          {documents.map((document) => {
            return <SummaryDocument key={getDocumentUuid(document)} document={document} instructionDoc={true} />;
          })}
        </div>
      ) : (
        <p>{emptyDocumentsMessage}</p>
      )}
    </div>
  );
};
