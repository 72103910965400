import { isDateExpired } from '../../validations/FieldsValidation';

export const checkIfCertificateExists = (certificateId, vesselCertificates, requestedCertificates) => {
  let c = vesselCertificates?.find((_) => _.documentType.id === certificateId);
  if (!c) c = requestedCertificates?.find((_) => _.documentType.id === certificateId);
  return c;
};

export const addOrRemoveCertificates = (vesselCertificates, requestedCertificates, id, name, subCat, cat) => {
  const foundInVc = vesselCertificates?.find((_) => _.documentType.id === id);
  if (!foundInVc || (foundInVc && isDateExpired(foundInVc?.expiryDate))) {
    const foundInRc = requestedCertificates?.find((_) => _.documentType.id === id);
    if (!foundInRc) {
      requestedCertificates.push({
        documentType: {
          category: cat,
          id: id,
          name: name,
          subcategory: subCat,
        },
      });
    } else {
      //found in requested certificates, no action
    }
  } else {
    //found in vessel certificates, no action
  }
  return requestedCertificates;
};
