import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SummaryDocument from './SummaryDocument';
import "../styles/summary.scss";
import UpdateIcon from "../styles/images/update-icon.svg";
import SuccessCheckmarkWhite from "../styles/images/successCheckmarkWhite.svg";
import { getRequiredDocuments } from '../../documents/slices/documentsSlice';

const UPDATE_DOCUMENTS_LABEL = 'Update documents'
const UPDATING_DOCUMENTS_LABEL = 'Updating...'
const SummaryDocumentsBlock = ({
  title,
  subtitle,
  documents,
  uploadSlice,
  emptyDocumentsMessage,
  filter,
  updateDocuments,
  setShowLoadingAlert
}) => {

  const params = useParams();
  const dispatch = useDispatch();
  const [isUpdating, setIsUpdating] = useState(false)

  useEffect(() => {
    setIsUpdating(updateDocuments)
  }, [updateDocuments]);

  const filterDocuments = () => {
    if (!documents) return [];

    if (!filter) return documents.filter(document => document?.documentUid != null);

    return documents.filter(document => {
      return document?.documentType?.subcategory === filter;
    });
  };

  const filteredDocuments = filterDocuments();

  const getDocumentUuid = (document) => document?.documentUid || document?.templateUid;

  const handleUpdate = () =>{
    setIsUpdating(true);
    setShowLoadingAlert(true)
    dispatch(uploadSlice({uid: params.uid})).then(()=>{
      dispatch(getRequiredDocuments({ uid: params.uid }));
      setIsUpdating(false)
      setShowLoadingAlert(false)
    });
  }

  if (filteredDocuments.length === 0) return null;

  return (
    <div className='summary-documents-block'>
      {title && (
        <h3 className={`document-type-title document-type-title_with-title-button`}>
          {title}
          {uploadSlice && <div className="update_documents title-button">
            <button
              onClick={handleUpdate}
              className="button"
            >
              <img className="button__icon-left" src={isUpdating ? SuccessCheckmarkWhite : UpdateIcon} alt="btn-icon" />
              <span className="button-label">{isUpdating ? UPDATING_DOCUMENTS_LABEL : UPDATE_DOCUMENTS_LABEL}</span>
            </button>
          </div>}
        </h3>
      )}
      {subtitle && (
        <h4 className="document-subtype-title">{subtitle}</h4>
      )}
        {filteredDocuments.length > 0 && (
          <div className="documents-block">
            {filteredDocuments.map(document => {
              return <SummaryDocument key={getDocumentUuid(document)} document={document} />
            })}
          </div>
        )}
        {filteredDocuments.length === 0 && (
          <p>{emptyDocumentsMessage}</p>
        )}
    </div>
  );
}

export default SummaryDocumentsBlock;
