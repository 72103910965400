import { Field } from '@navozyme/uikit/dist/atoms/Field';
import { DatePickerTimeInput } from '@navozyme/uikit/dist/molecules/DatePicker';
import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { booleanOptionsForRadioGroup } from '../../../../../features/forms/helpers/random-form-helpers';
import FlexibleInput from '../../../../forms/components/FlexibleInput';
import Input from '../../../../forms/components/Input';
import RadioButtonsCustom from '../../../../forms/components/RadioButtonsCustom';
import { FormRefContext } from '../../../../global/context/FormRefContext';
import { getPortByUnlocode } from '../../../../global/utils/ports';
import PageHeader from '../../../../layout/components/PageHeader';
import { selectSolas, updateIsps } from '../../../slices/portCallSlice';
import { isEmpty, MandatoryFieldMessages } from '../../../validations/FieldsValidation';
import SkipAndNextButtons from '../../port-call-data/components/SkipAndNextButtons';
import { DocumentCategory, DocumentSubCategory, CERTIFICATES } from '../../documents/constants';
import { getRequiredDocuments, requestDocuments } from '../../documents/slices/documentsSlice';
import { Modal } from '@navozyme/uikit/dist/molecules/Modal';
import Send from '@navozyme/uikit/dist/atoms/Icons/Send';
import '../styles/solas-questions.scss';

const validationSchema = Yup.object({
  isscOptions: Yup.string(),
  sspOptions: Yup.string(),
  shipSecurityLevelOptions: Yup.string(),
  hasValidISSC: Yup.boolean(),
  CSOName: Yup.string().required('This field is required'),
  contactDetails24h: Yup.string().required('This field is required').max(25, 'Please, maximum 25 characters required'),
  certificateIssuer: Yup.string()
    .nullable()
    .when('hasValidISSC', {
      is: true,
      then: (schema) => schema.required('This field is required'),
    }),
  certificateNumber: Yup.string()
    .nullable()
    .when('hasValidISSC', {
      is: true,
      then: (schema) => schema.required('This field is required'),
    }),
  certificateExpiryDate: Yup.string()
    .nullable()
    .when('hasValidISSC', {
      is: true,
      then: (schema) => schema.required('This field is required'),
    }),
});

const isscOptions = booleanOptionsForRadioGroup();
const sspOptions = booleanOptionsForRadioGroup();

const shipSecurityLevelOptions = [
  { key: 'Level 1', value: 'LEVEL_1' },
  { key: 'Level 2', value: 'LEVEL_2' },
  { key: 'Level 3', value: 'LEVEL_3' },
];

const locationOptions = {
  at_sea: { key: 'At sea', value: 'AT_SEA' },
  at_port: { key: 'At port', value: 'AT_PORT' },
};

const locationOptionsToArr = () => {
  const arr = [];
  Object.keys(locationOptions).forEach((key) => {
    arr.push(locationOptions[key]);
  });
  return arr;
};

function SolasQuestions() {
  const formRef = useRef();
  const { formContextRef } = useContext(FormRefContext);

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isps = useSelector(selectSolas);
  const [isIsscModalOpen, setIsIsscModalOpen] = useState(false);

  useEffect(() => {
    formContextRef.current = formRef.current;
  }, [formContextRef, formRef, isps]);

  const [initialValues, setInitialValues] = useState({
    ...isps.data,
    certificateExpiryDate: isps.data.certificateExpiryDate ? new Date(isps.data.certificateExpiryDate) : null,
  });

  useEffect(() => {
    const fetchData = async () => {
      let relatedPort = {};
      try {
        if (isps?.data?.shipSecurityLevelLocation) {
          relatedPort = await getPortByUnlocode({
            ports: isps?.relations?.ports,
            unlocode: isps?.data?.shipSecurityLevelLocation,
          });
        }
      } catch (e) {
        console.error('Port is undefined');
      }
      setInitialValues({
        ...isps.data,
        certificateExpiryDate: isps.data.certificateExpiryDate ? new Date(isps.data.certificateExpiryDate) : null,
        locationRadio: isps.data.shipSecurityLevelLocation
          ? locationOptions.at_port.value
          : locationOptions.at_sea.value,
        dropdownShipSecurityLevelLocation: relatedPort,
      });
    };
    if (Object.keys(isps.data).length > 0) fetchData();
  }, [isps.data]);

  const isLocationAtSea = (locationOptionValue) => locationOptionValue === locationOptions.at_sea.value;

  const handleLocationChange = (value, values) => {
    setInitialValues({
      ...values,
      locationRadio: isLocationAtSea(value) ? locationOptions.at_sea.value : locationOptions.at_port.value,
    });
  };

  const getCertificatesToRequest = async () => {
    const requiredDocuments = await dispatch(getRequiredDocuments({ uid: params.uid }));
    let requestedCertificates = requiredDocuments?.payload?.documents?.requestedCertificates ?? [];
    let issc = requestedCertificates.find((_) => _.documentType.id === CERTIFICATES.ISSC.id);
    let certificatesToRequest = [];
    if (!issc) {
      certificatesToRequest.push({
        documentType: {
          category: DocumentCategory.OFFICIAL_LIST_CERTIFICATES,
          id: CERTIFICATES.ISSC.id,
          name: CERTIFICATES.ISSC.name,
          subcategory: DocumentSubCategory.SafetyAndSecurityCertificates,
        },
      });
      if (certificatesToRequest.length > 0 && requestedCertificates.length > 0) {
        certificatesToRequest = certificatesToRequest.concat(requestedCertificates);
      }
    }
    return certificatesToRequest;
  };

  const removeISSC = async () => {
    const requiredDocuments = await dispatch(getRequiredDocuments({ uid: params.uid }));
    let requestedCertificates = requiredDocuments?.payload?.documents?.requestedCertificates ?? [];
    let certificatesWithoutIssc = requestedCertificates.filter((_) => _.documentType.id !== CERTIFICATES.ISSC.id);
    return certificatesWithoutIssc;
  };

  const handleSubmit = async (values) => {
    let finalValues = {
      ...values,
      sspNoReason: values.hasApprovedSSP ? null : values.sspNoReason,
      certificateExpiryDate: values.certificateExpiryDate ? values.certificateExpiryDate.toISOString() : undefined,
    };

    if (isLocationAtSea(values.locationRadio)) {
      finalValues.shipSecurityLevelLocation = null;
    } else {
      finalValues.shipSecurityLevelSeaLocation = null;
      if (!finalValues.shipSecurityLevelLocation)
        finalValues.shipSecurityLevelLocation = finalValues.dropdownShipSecurityLevelLocation;
    }

    if (!values.hasValidISSC) {
      finalValues.certificateIssuer = '';
      finalValues.certificateNumber = '';
      finalValues.certificateExpiryDate = null;
      initialValues.certificateIssuer = '';
      initialValues.certificateNumber = '';
      initialValues.certificateExpiryDate = null;
      if (initialValues.hasValidISSC) {
        let certificatesWithoutIssc = (await removeISSC()) ?? [];
        dispatch(
          requestDocuments({
            uid: params.uid,
            category: 'requestedCertificates',
            data: certificatesWithoutIssc,
          })
        );
      }
    } else {
      const certificatesToRequest = await getCertificatesToRequest();
      if (certificatesToRequest.length > 0) {
        dispatch(
          requestDocuments({
            uid: params.uid,
            category: 'requestedCertificates',
            data: certificatesToRequest,
          })
        );
      }
    }

    delete finalValues.dropdownShipSecurityLevelLocation;

    await dispatch(
      updateIsps({
        ispsData: { ...finalValues },
        uid: params.uid,
      })
    );
  };

  const renderConfirmationModal = (values) => {
    if (!values.hasValidISSC && initialValues.hasValidISSC) {
      setIsIsscModalOpen(true);
      return false;
    }
    validation(values);
    handleSubmit(values);
  };

  const setISSCYes = (values) => {
    setIsIsscModalOpen(false);
    values.hasValidISSC = true;

    values.certificateIssuer = initialValues.certificateIssuer;
    values.certificateNumber = initialValues.certificateNumber;
    values.certificateExpiryDate = initialValues.certificateExpiryDate;
  };

  const validation = async (values) => {
    try {
      const res = await validationSchema.validate(values, { abortEarly: false });
      navigate(`/${params.uid}/solas-and-isps-code/port-calls`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='solas-questions'>
      <PageHeader title={'SOLAS and ISPS code'} subtitle={`Provide the Chief Security Officer contact information:`} />
      <Formik
        innerRef={formRef}
        onSubmit={() => {}}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}>
        {({ values, setValues, errors }) => (
          <Form className='solas-questions-form'>
            <Modal
              id='submit-waste-info'
              width={600}
              isOpen={isIsscModalOpen}
              onModalClose={() => setIsIsscModalOpen(false)}
              iconColor='nmap.500'
              title={'Confirm the action'}
              subTitle={
                'This action will remove the certificate from the system. Are you sure the ISSC certificate is not valid anymore?'
              }
              icon={<Send />}>
              {
                <Modal.Actions
                  cancelLabel='Back'
                  cancelColor='grey700'
                  actionCancel={() => {
                    setISSCYes(values);
                  }}
                  actionAction={() => {
                    validation(values);
                    handleSubmit(values);
                  }}
                  actionLabel='Confirm'
                  actionColor='nmap500'
                />
              }
            </Modal>
            <div className='solas-questions-form__row'>
              <Input
                name='CSOName'
                placeholder='Name'
                label='Full name *'
                hasError={isEmpty(values?.CSOName)}
                errorMessage={MandatoryFieldMessages.RequiredField}
              />
              <Input
                name='contactDetails24h'
                placeholder='Contact details'
                label='24 hrs contact details *'
                hasError={isEmpty(values?.contactDetails24h)}
                errorMessage={MandatoryFieldMessages.RequiredField}
              />
            </div>
            <div className='solas-questions-form__row'>
              <RadioButtonsCustom
                name='hasValidISSC'
                label='Does the ship have a valid International Ship Security Certificate (ISSC)?'
                options={isscOptions}
              />
            </div>
            {values.hasValidISSC && (
              <div className='solas-questions-form__row'>
                <Input
                  name='certificateIssuer'
                  placeholder='Type one'
                  label='Issued by (name of Administr. or RSO) *'
                  hasError={isEmpty(values?.certificateIssuer)}
                  errorMessage={MandatoryFieldMessages.RequiredField}
                />
                <Input
                  name='certificateNumber'
                  placeholder='Certificate number'
                  label='Certificate number *'
                  hasError={isEmpty(values?.certificateNumber)}
                  errorMessage={MandatoryFieldMessages.RequiredField}
                />
                <Field label='Expiry date *' className='field_date_picker'>
                  <DatePickerTimeInput
                    key={initialValues?.certificateExpiryDate + 'certificateExpiryDate'}
                    name='certificateExpiryDate'
                    sizeInput='large'
                    minDate={new Date()}
                    showTime={false}
                    defaultValue={initialValues?.certificateExpiryDate}
                    action={({ isoDate }) => setValues({ ...values, certificateExpiryDate: new Date(isoDate) })}
                    showBlueError={true}
                    hasError={isEmpty(values?.certificateExpiryDate)}
                    errorMessage={MandatoryFieldMessages.RequiredField}
                  />
                </Field>
              </div>
            )}
            {!values.hasValidISSC && (
              <div className='solas-questions-form__row'>
                <Input name='noISSCReason' placeholder='Why not?' label='Why not?' />
              </div>
            )}
            <div className='solas-questions-form__row'>
              <RadioButtonsCustom
                name='hasApprovedSSP'
                label='Does the ship have an approved SSP on board?'
                options={sspOptions}
              />
            </div>
            {!values.hasApprovedSSP && (
              <div className='solas-questions-form__row'>
                <Input name='sspNoReason' placeholder='Why not?' label='Why not?' />
              </div>
            )}
            <div className='solas-questions-form__row'>
              <RadioButtonsCustom
                name='shipSecurityLevel'
                label='Ship Security Level'
                options={shipSecurityLevelOptions}
              />
            </div>
            <div className='solas-questions-form__row'>
              <div className='dependant-input'>
                <RadioButtonsCustom
                  name='locationRadio'
                  label='Indicate the location of the vessel at the moment of declaring the Security Level:'
                  options={locationOptionsToArr()}
                  onOptionClick={(value) => handleLocationChange(value, values)}
                />
                {initialValues.locationRadio != null && (
                  <FlexibleInput
                    inputName='shipSecurityLevelSeaLocation'
                    dropdownName='dropdownShipSecurityLevelLocation'
                    placeholder='Location'
                    isTextInput={isLocationAtSea(values.locationRadio)}
                    dropdownID='portLocationDropdown'
                    getSpecificValue='unlocode'
                  />
                )}
              </div>
            </div>

            <SkipAndNextButtons
              next={`/${params.uid}/solas-and-isps-code/port-calls`}
              back={`/${params.uid}/dangerous-goods`}
              onClick={() => {
                renderConfirmationModal(values);
              }}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default SolasQuestions;
