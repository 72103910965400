import React, { useEffect } from 'react';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import SkipAndNextButtons from '../../port-call-data/components/SkipAndNextButtons';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../../../../layout/components/PageHeader';
import Page from '../../../../layout/components/Page';
import UploadDownloadBox from '../../local-formalities/components/UploadDownloadBox';
import ProgressBar from '../../../../layout/components/ProgressBar';
import {
  selectShipCertificatesByTitle,
  selectVesselCertificates,
  getRequiredDocuments,
  selectDocuments,
  getVesselCertificates,
  requestDocuments,
} from '../../documents/slices/documentsSlice';
import { getVessel } from '../../../slices/portCallSlice';
import { CERTIFICATES, DocumentSubCategory, DocumentCategory } from '../../documents/constants';
import { checkIfCertificateExists, addOrRemoveCertificates } from '../handleCertificates';

function ShipCertificates() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const params = useParams();
  const shipCertificatesByTitle = useSelector(selectShipCertificatesByTitle);

  const requiredDocuments = useSelector(selectDocuments);
  const vesselCertificates = useSelector(selectVesselCertificates);

  useEffect(() => {
    dispatch(getVessel(params.uid)).then((response) => {
      dispatch(getVesselCertificates({ vesselUid: response.payload.uid }));
    });
    dispatch(getRequiredDocuments({ uid: params.uid }));
  }, [params.uid]);

  useEffect(() => {
    async function call() {
      if (vesselCertificates && requiredDocuments?.documents?.requestedCertificates) await addOrRemoveCLCCerts();
    }
    call();
  }, [vesselCertificates]);

  const addOrRemoveCLCCerts = async () => {
    let requestedCertificates = [...requiredDocuments?.documents?.requestedCertificates] ?? [];
    let iso14001 = checkIfCertificateExists(CERTIFICATES.ISO_14001.id, vesselCertificates, requestedCertificates);

    if (iso14001 && iso14001?.documentUid) {
      let initialRequestedCerts = JSON.stringify(requestedCertificates);

      requestedCertificates = addOrRemoveCertificates(
        vesselCertificates,
        requestedCertificates,
        CERTIFICATES.BCLC.id,
        CERTIFICATES.BCLC.name,
        DocumentSubCategory.CLCConvention,
        DocumentCategory.OFFICIAL_LIST_CERTIFICATES
      );

      requestedCertificates = addOrRemoveCertificates(
        vesselCertificates,
        requestedCertificates,
        CERTIFICATES.CLC.id,
        CERTIFICATES.CLC.name,
        DocumentSubCategory.CLCConvention,
        DocumentCategory.OFFICIAL_LIST_CERTIFICATES
      );

      let finalRequestedCerts = JSON.stringify(requestedCertificates);
      if (finalRequestedCerts !== initialRequestedCerts) {
        dispatch(
          requestDocuments({
            uid: params.uid,
            category: 'requestedCertificates',
            data: requestedCertificates,
          })
        );
      }
    }
  };

  const renderBody = () => {
    return _.isEmpty(shipCertificatesByTitle) ? renderEmptyCertificates() : renderCertificates();
  };

  const renderCertificates = () => {
    return (
      <>
        <PageHeader title={'Ship Certificates'} />
        {Object.keys(shipCertificatesByTitle).map((title, i) => {
          return (
            <div key={`doc-category-${i}`}>
              <h3>{title}</h3>
              <p>Please provide the requested ship certificates:</p>
              <div className='documents-list'>
                {shipCertificatesByTitle[title].map((document, i) => {
                  let isMandatory = document?.documentType?.id !== CERTIFICATES.ISO_14001.id;
                  return (
                    <UploadDownloadBox
                      key={`doc-${i}`}
                      category='requestedCertificates'
                      document={document}
                      documentUid={document.documentUid}
                      isMandatory={isMandatory}
                      callback={addOrRemoveCLCCerts}
                      isShipCertificate={true}>
                      {document.documentType.name}
                    </UploadDownloadBox>
                  );
                })}
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const renderEmptyCertificates = () => {
    return <PageHeader title={'Ship Certificates'} subtitle={'No certificates requested.'} />;
  };

  return (
    <Page>
      <div className='safety-documents'>
        <ProgressBar percentage={'100'} pageNumber={1} totalPages={'1'} />
        {renderBody()}
        <SkipAndNextButtons
          onClick={() => navigate(`/${params.uid}/general-declaration`)}
          next={`/${params.uid}/general-declaration`}
          back={`/${params.uid}/local-formalities`}
        />
      </div>
    </Page>
  );
}

export default ShipCertificates;
