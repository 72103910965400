export enum MandatoryFieldMessages {
  RequiredField = '* Required data field',
  RequiredDocument = '* Required document',
  RequiredTable = '* Required to provide at least one port call.',
  WrongFormat = 'Wrong format',
}

export const isEmpty = (value: any): boolean => {
  return value === null || value === '' || value === undefined;
};

export const isEmptyNumber = (value: any): boolean => {
  return value === '' || value === null || value === undefined || isNaN(value);
};

export const isEmptyObject = (obj: any): boolean => {
  return obj === null || obj === undefined || Object.keys(obj).length === 0;
};

export const isDateExpired = (date: string) => {
  const inputDate = new Date(date);
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  return inputDate < currentDate;
};
