import { Form, Formik } from 'formik';
import _ from 'lodash';
import diff from 'object-diff';
import React, { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { booleanOptionsForRadioGroup } from '../../../../../features/forms/helpers/random-form-helpers';
import { isPassengerShip } from '../../../../../features/layout/components/Sidebar';
import DangerousGoodsOptionsCheckboxGroup from '../../../../forms/components/DangerousGoodsOptionsCheckboxGroup';
import Input from '../../../../forms/components/Input';
import RadioButtonsCustom from '../../../../forms/components/RadioButtonsCustom';
import Textarea from '../../../../forms/components/TextArea';
import { FormRefContext } from '../../../../global/context/FormRefContext';
import PageHeader from '../../../../layout/components/PageHeader';
import {
  getDangerousGoodsData,
  getInspections,
  selectCargo,
  selectDangerousGoods,
  selectGeneralData,
  selectInspections,
  updateCargo,
  updateDangerousGoodsData,
  updateInspections,
  updatePortCall,
} from '../../../slices/portCallSlice';
import { isEmpty, isEmptyNumber, MandatoryFieldMessages } from '../../../validations/FieldsValidation';
import SkipAndNextButtons from '../../port-call-data/components/SkipAndNextButtons';
import { selectCargoDocumentsByTitle, requestDocuments } from '../../documents/slices/documentsSlice';
import '../styles/cargo-information.scss';
import { DocumentCategory, DocumentSubCategory, CARGO_DOUMENTS } from '../../documents/constants';

const validationSchema = Yup.object({
  dangerousGoodsType: Yup.array(),
  arrivalCargo: Yup.object({
    volume: Yup.number().nullable().min(0, 'Must be a positive number'),
  }).nullable(),
  departureCargo: Yup.object({
    volume: Yup.number().nullable().min(0, 'Must be a positive number'),
  }).nullable(),
});

const IS_UNLOADING_DANGEROUS_GOODS = 'isUnloadingDangerousGoods';
const IS_LOADING_DANGEROUS_GOODS = 'isLoadingDangerousGoods';
const IS_DANGEROUS_GOODS_IN_TRANSIT = 'isDangerousGoodsInTransit';
const IS_NOT_CARRYING_DANGEROUS_GOODS = 'isNotCarryingDangerousGoods';

const allDangerousGoodsStatusValues = [
  IS_NOT_CARRYING_DANGEROUS_GOODS,
  IS_UNLOADING_DANGEROUS_GOODS,
  IS_LOADING_DANGEROUS_GOODS,
  IS_DANGEROUS_GOODS_IN_TRANSIT,
];

const dangerousGoodsTypeOptions = [
  { key: 'Unloading dangerous goods ', value: IS_UNLOADING_DANGEROUS_GOODS },
  { key: 'Loading dangerous goods ', value: IS_LOADING_DANGEROUS_GOODS },
  { key: 'Dangerous goods in transit ', value: IS_DANGEROUS_GOODS_IN_TRANSIT },
  { key: 'Not carrying dangerous goods ', value: IS_NOT_CARRYING_DANGEROUS_GOODS },
];

const cargoTanksConditionOptions = [
  { key: 'Full', value: 'Full' },
  { key: 'Empty', value: 'Empty' },
  { key: 'Inerted', value: 'Inerted' },
];
const ballastTankConditionOptions = [
  { key: 'Full', value: 'Full' },
  { key: 'Empty', value: 'Empty' },
  { key: 'Inerted', value: 'Inerted' },
];

const vesselCarryingCargoOptions = booleanOptionsForRadioGroup();

function CargoInformation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const shipType = localStorage.getItem('shipType');
  const backUrl = isPassengerShip(shipType)
    ? `/${params.uid}/passengers/departure-passengers`
    : `/${params.uid}/crew/effects`;

  useEffect(() => {
    dispatch(getDangerousGoodsData(params.uid));
    dispatch(getInspections(params.uid));
  }, []);

  const { arrivalCargo, departureCargo, declaredCargo } = useSelector(selectCargo);
  const cargoDocumentsByTitle = useSelector(selectCargoDocumentsByTitle);
  const dangerousGoods = useSelector(selectDangerousGoods);
  const { isVesselCarryingCargo } = useSelector(selectGeneralData);
  const inspections = useSelector(selectInspections);

  const formRef = useRef();
  const { formContextRef } = useContext(FormRefContext);

  function getCargoDocumentsToRequest() {
    let cargoDocs, manifestDoc, billOfLadingDoc, qualityCertificateDoc;
    if (!_.isEmpty(cargoDocumentsByTitle)) {
      cargoDocs = cargoDocumentsByTitle[DocumentSubCategory.CargoDocuments];
      manifestDoc = cargoDocs?.find((doc) => doc.documentType.id === CARGO_DOUMENTS.CARGO_MANIFEST.id);
      billOfLadingDoc = cargoDocs?.find((doc) => doc.documentType.id === CARGO_DOUMENTS.BILL_OF_LADING.id);
      qualityCertificateDoc = cargoDocs?.find(
        (doc) => doc.documentType.id === CARGO_DOUMENTS.QUALITY_CERTIFICATE_OF_CARGO.id
      );
    }
    let documentsToRequest = [];
    if (!manifestDoc)
      documentsToRequest.push({
        documentType: {
          id: CARGO_DOUMENTS.CARGO_MANIFEST.id,
          name: CARGO_DOUMENTS.CARGO_MANIFEST.name,
          category: DocumentCategory.CARGO_DOCUMENTS,
          subcategory: DocumentSubCategory.CargoDocuments,
        },
        name: CARGO_DOUMENTS.CARGO_MANIFEST.name,
        status: 'Draft',
      });

    if (!billOfLadingDoc)
      documentsToRequest.push({
        documentType: {
          id: CARGO_DOUMENTS.BILL_OF_LADING.id,
          name: CARGO_DOUMENTS.BILL_OF_LADING.name,
          category: DocumentCategory.CARGO_DOCUMENTS,
          subcategory: DocumentSubCategory.CargoDocuments,
        },
        name: CARGO_DOUMENTS.BILL_OF_LADING.name,
        status: 'Draft',
      });

    if (!qualityCertificateDoc)
      documentsToRequest.push({
        documentType: {
          id: CARGO_DOUMENTS.QUALITY_CERTIFICATE_OF_CARGO.id,
          name: CARGO_DOUMENTS.QUALITY_CERTIFICATE_OF_CARGO.name,
          category: DocumentCategory.CARGO_DOCUMENTS,
          subcategory: DocumentSubCategory.CargoDocuments,
        },
        name: CARGO_DOUMENTS.QUALITY_CERTIFICATE_OF_CARGO.name,
        status: 'Draft',
      });

    if (documentsToRequest && cargoDocs) {
      documentsToRequest = documentsToRequest.concat(cargoDocs);
    }
    return documentsToRequest;
  }

  useEffect(() => {
    formContextRef.current = formRef.current;
  }, [formContextRef, formRef, arrivalCargo, departureCargo, isVesselCarryingCargo, inspections]);

  const computeInitialValues = () => {
    let initialValues = {
      arrivalCargo,
      departureCargo,
      dangerousGoodsType: [IS_NOT_CARRYING_DANGEROUS_GOODS],
      isVesselCarryingCargo,
    };

    const { dangerousGoodsData } = dangerousGoods;

    if (dangerousGoodsData) {
      initialValues = {
        ...initialValues,
        ...JSON.parse(JSON.stringify(dangerousGoodsData)),
      };

      if (dangerousGoodsData.dangerousGoodsStatus) {
        initialValues.dangerousGoodsType = [];

        if (dangerousGoodsData.dangerousGoodsStatus[IS_DANGEROUS_GOODS_IN_TRANSIT]) {
          initialValues.dangerousGoodsType.push(IS_DANGEROUS_GOODS_IN_TRANSIT);
        }

        if (dangerousGoodsData.dangerousGoodsStatus[IS_UNLOADING_DANGEROUS_GOODS]) {
          initialValues.dangerousGoodsType.push(IS_UNLOADING_DANGEROUS_GOODS);
        }

        if (dangerousGoodsData.dangerousGoodsStatus[IS_LOADING_DANGEROUS_GOODS]) {
          initialValues.dangerousGoodsType.push(IS_LOADING_DANGEROUS_GOODS);
        }

        if (dangerousGoodsData.dangerousGoodsStatus[IS_NOT_CARRYING_DANGEROUS_GOODS]) {
          initialValues.dangerousGoodsType = [IS_NOT_CARRYING_DANGEROUS_GOODS];
        }
      }
    }

    if (inspections?.data) {
      initialValues.conditionOfCargoTanks = inspections?.data?.conditionOfCargoTanks;
      initialValues.conditionOfBallastTanks = inspections?.data?.conditionOfBallastTanks;
    }

    return initialValues;
  };

  const initialValues = computeInitialValues();

  const handleSubmit = async (values) => {
    const changedValues = diff(initialValues, values);

    // Check if isVesselCarryingCargo has changed and dispatch updatePortCall
    if (values.isVesselCarryingCargo !== initialValues.isVesselCarryingCargo) {
      dispatch(
        updatePortCall({
          uid: params.uid,
          isVesselCarryingCargo: values.isVesselCarryingCargo,
        })
      );
    }

    if (values.isVesselCarryingCargo || values.isVesselCarryingCargo === 'true') {
      let cargoDocsToRequest = getCargoDocumentsToRequest();
      if (cargoDocsToRequest.length > 0) {
        dispatch(
          requestDocuments({
            uid: params.uid,
            category: 'cargoDocuments',
            data: cargoDocsToRequest,
          })
        );
      }
    }

    if (
      values.conditionOfCargoTanks !== initialValues.conditionOfCargoTanks ||
      values.conditionOfBallastTanks !== initialValues.conditionOfBallastTanks
    ) {
      let finalValues = { ...inspections?.data };
      finalValues.conditionOfCargoTanks = values.conditionOfCargoTanks;
      finalValues.conditionOfBallastTanks = values.conditionOfBallastTanks;
      dispatch(
        updateInspections({
          uid: params.uid,
          data: finalValues,
        })
      );
    }
    if (Object.keys(changedValues).length) {
      // Create the object structure as needed for updateCargo
      const cargoUpdatePayload = {
        arrivalCargo: values.arrivalCargo,
        departureCargo: values.departureCargo,
        cargoToDeclare: declaredCargo.length > 0,
        ...(declaredCargo.length > 0 && { declaredCargo }),
      };
      dispatch(updateCargo(cargoUpdatePayload));

      const dangerousGoodsStatus = {};
      values.dangerousGoodsType.forEach((key) => {
        dangerousGoodsStatus[key] = true;
      });

      allDangerousGoodsStatusValues.forEach((key) => {
        if (!(key in dangerousGoodsStatus) || !dangerousGoodsStatus[key]) {
          dangerousGoodsStatus[key] = false;
        }
      });

      // Merge the current dangerousGoodsData with the updated dangerousGoodsStatus
      const updatedDangerousGoodsData = {
        ...dangerousGoods.dangerousGoodsData,
        dangerousGoodsStatus,
        isTransportingHeavyOils: values?.isTransportingHeavyOils,
      };
      dispatch(
        updateDangerousGoodsData({
          uid: params.uid,
          dangerousGoodsData: updatedDangerousGoodsData,
        })
      );
    }
  };

  return (
    <div className='cargo-information'>
      <PageHeader title={'Cargo - General information'} style />
      <Formik
        onSubmit={handleSubmit}
        innerRef={formRef}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}>
        {({ values, errors, touched, isSubmitting, setErrors }) => (
          <Form className='cargo-information-form'>
            <Form className='cargo-information-form__row'>
              <RadioButtonsCustom
                name='isVesselCarryingCargo'
                label='Are you carrying or will carry cargo? *'
                options={vesselCarryingCargoOptions}
              />
            </Form>
            <div className='cargo-information-form__row'>
              <RadioButtonsCustom
                name='conditionOfCargoTanks'
                label='Condition of cargo tanks'
                options={cargoTanksConditionOptions}
              />
              <RadioButtonsCustom
                name='conditionOfBallastTanks'
                label='Condition of ballast tanks'
                options={ballastTankConditionOptions}
              />
            </div>

            {values.isVesselCarryingCargo && (
              <div className='cargo-information-form__controls'>
                <div div className='cargo-information-form__row'>
                  <Textarea
                    name='arrivalCargo.description'
                    rows={6}
                    placeholder='Brief description of the cargo at arrival'
                    label='Brief description of the cargo at arrival *'
                    hasError={isEmpty(values?.arrivalCargo?.description)}
                    errorMessage={MandatoryFieldMessages.RequiredField}
                  />
                  <Textarea
                    name='departureCargo.description'
                    rows={6}
                    placeholder='Brief description of the cargo at departure'
                    label='Brief description of the cargo at departure *'
                    hasError={isEmpty(values?.departureCargo?.description)}
                    errorMessage={MandatoryFieldMessages.RequiredField}
                  />
                </div>
                <div className='cargo-information-form__row'>
                  <Input
                    name='arrivalCargo.volume'
                    type='number'
                    placeholder='Volume of cargo to be unloaded (m3)'
                    label='Volume of cargo to be unloaded (m3) *'
                    hasError={isEmptyNumber(values?.arrivalCargo?.volume)}
                    errorMessage={MandatoryFieldMessages.RequiredField}
                  />
                  <Input
                    name='departureCargo.volume'
                    type='number'
                    placeholder='Volume of cargo to be loaded (m3)'
                    label='Volume of cargo to be loaded (m3) *'
                    hasError={isEmptyNumber(values?.departureCargo?.volume)}
                    errorMessage={MandatoryFieldMessages.RequiredField}
                  />
                </div>
                <div className='cargo-information-form__row'>
                  <RadioButtonsCustom
                    name='isTransportingHeavyOils'
                    label='Are you carrying commercially heavy fuel oil, tar, bitumen or heavy crude oil? *'
                    options={vesselCarryingCargoOptions}
                    onBlur={() => formRef.current.submitForm()}
                  />
                </div>
                <div className='dangerous-goods-form__row'>
                  <DangerousGoodsOptionsCheckboxGroup
                    name='dangerousGoodsType'
                    label='Select the options that fit better: *'
                    options={dangerousGoodsTypeOptions}
                  />
                </div>
              </div>
            )}
            <SkipAndNextButtons
              next={`/${params.uid}/cargo/list`}
              back={backUrl}
              onClick={() => navigate(`/${params.uid}/cargo/list`)}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CargoInformation;
