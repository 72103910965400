export const europeanCountriesList = [
  { value: 'AT', label: 'Austria', code: 'AT', code3: 'AUT', name: 'Austria', number: '040' },
  { value: 'BE', label: 'Belgium', code: 'BE', code3: 'BEL', name: 'Belgium', number: '056' },
  { value: 'BG', label: 'Bulgaria', code: 'BG', code3: 'BGR', name: 'Bulgaria', number: '100' },
  { value: 'HR', label: 'Croatia', code: 'HR', code3: 'HRV', name: 'Croatia', number: '191' },
  { value: 'CY', label: 'Cyprus', code: 'CY', code3: 'CYP', name: 'Cyprus', number: '196' },
  { value: 'CZ', label: 'Czechia', code: 'CZ', code3: 'CZE', name: 'Czechia', number: '203' },
  { value: 'DK', label: 'Denmark', code: 'DK', code3: 'DNK', name: 'Denmark', number: '208' },
  { value: 'EE', label: 'Estonia', code: 'EE', code3: 'EST', name: 'Estonia', number: '233' },
  { value: 'FI', label: 'Finland', code: 'FI', code3: 'FIN', name: 'Finland', number: '246' },
  { value: 'FR', label: 'France', code: 'FR', code3: 'FRA', name: 'France', number: '250' },
  { value: 'DE', label: 'Germany', code: 'DE', code3: 'DEU', name: 'Germany', number: '276' },
  { value: 'GR', label: 'Greece', code: 'GR', code3: 'GRC', name: 'Greece', number: '300' },
  { value: 'HU', label: 'Hungary', code: 'HU', code3: 'HUN', name: 'Hungary', number: '348' },
  { value: 'IS', label: 'Iceland', code: 'IS', code3: 'ISL', name: 'Iceland', number: '352' },
  { value: 'IE', label: 'Ireland', code: 'IE', code3: 'IRL', name: 'Ireland', number: '372' },
  { value: 'IT', label: 'Italy', code: 'IT', code3: 'ITA', name: 'Italy', number: '380' },
  { value: 'LV', label: 'Latvia', code: 'LV', code3: 'LVA', name: 'Latvia', number: '428' },
  { value: 'LI', label: 'Liechtenstein', code: 'LI', code3: 'LIE', name: 'Liechtenstein', number: '438' },
  { value: 'LT', label: 'Lithuania', code: 'LT', code3: 'LTU', name: 'Lithuania', number: '440' },
  { value: 'LU', label: 'Luxembourg', code: 'LU', code3: 'LUX', name: 'Luxembourg', number: '442' },
  { value: 'MT', label: 'Malta', code: 'MT', code3: 'MLT', name: 'Malta', number: '470' },
  { value: 'NL', label: 'Netherlands (the)', code: 'NL', code3: 'NLD', name: 'Netherlands (the)', number: '528' },
  { value: 'NO', label: 'Norway', code: 'NO', code3: 'NOR', name: 'Norway', number: '578' },
  { value: 'PL', label: 'Poland', code: 'PL', code3: 'POL', name: 'Poland', number: '616' },
  { value: 'PT', label: 'Portugal', code: 'PT', code3: 'PRT', name: 'Portugal', number: '620' },
  { value: 'RO', label: 'Romania', code: 'RO', code3: 'ROU', name: 'Romania', number: '642' },
  { value: 'SK', label: 'Slovakia', code: 'SK', code3: 'SVK', name: 'Slovakia', number: '703' },
  { value: 'SI', label: 'Slovenia', code: 'SI', code3: 'SVN', name: 'Slovenia', number: '705' },
  { value: 'ES', label: 'Spain', code: 'ES', code3: 'ESP', name: 'Spain', number: '724' },
  { value: 'SE', label: 'Sweden', code: 'SE', code3: 'SWE', name: 'Sweden', number: '752' },
  { value: 'GB', label: 'United Kingdom of Great Britain and Northern Ireland (the)', code: 'GB', code3: 'GBR', name: 'United Kingdom of Great Britain and Northern Ireland (the)', number: '826' },
];
