import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../../../config/axios';
import auth from '../../../../../config/auth';

const initialState = {
  searchResults: {},
  loading: {},
};

export const getPortFacilitiesFromApi = async ({ portUnlocode }) => {
  const headers = auth.getHeaders();
  const endpoint = `/ports/${portUnlocode}/port-facilities`;
  const response = await axiosInstance.get(endpoint, { headers });

  return response.data.data;
};

export const searchPortFacilities = createAsyncThunk(
  'port/searchPortFacilities',
  async ({ dropdownID, portUnlocode }) => {
    const headers = auth.getHeaders();
    const endpoint = `/ports/${portUnlocode}/port-facilities`;
    const response = await axiosInstance.get(endpoint, { headers });

    return {
      dropdownID,
      data: response.data.data,
    };
  }
);

export const searchPortFacilitiesSlice = createSlice({
  name: 'searchPortFacilities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(searchPortFacilities.pending, (state, { meta }) => {
        const { dropdownID } = meta.arg;
        state.loading[dropdownID] = true;
      })
      .addCase(searchPortFacilities.fulfilled, (state, action) => {
        const { dropdownID, data } = action.payload;
        state.searchResults[dropdownID] = data;
        state.loading[dropdownID] = false;
      })
      .addCase(searchPortFacilities.rejected, (state, { meta }) => {
        const { dropdownID } = meta.arg;
        state.loading[dropdownID] = false;
      });
  },
});

export const {} = searchPortFacilitiesSlice.actions;

export const selectSearchPortFacilitiesResults = (state, dropdownId) =>
  state.searchPortFacilities.searchResults[dropdownId];
export const selectSearchPortFacilitiesLoadingState = (state, dropdownId) =>
  state.searchPortFacilities.loading[dropdownId];

export default searchPortFacilitiesSlice.reducer;
