import { Form, Formik, useFormikContext } from 'formik';
import diff from 'object-diff';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Input from '../../../../forms/components/Input';
import MultiSelect from '../../../../forms/components/MultiSelect';
import { FormRefContext } from '../../../../global/context/FormRefContext';
import { countryListAllIsoData } from '../../../../global/utils/countriesList';
import Button from '../../../../layout/components/Button';
import DiscardButton from '../../../../layout/components/DiscardButton';
import PageHeader from '../../../../layout/components/PageHeader';
import { selectCrew, updateCrew } from '../../../slices/portCallSlice';
import { isEmpty, MandatoryFieldMessages } from '../../../validations/FieldsValidation';
import SkipAndNextButtons from '../../port-call-data/components/SkipAndNextButtons';
import editIcon from '../../vessel-data/styles/images/editIcon.svg';
import '../styles/confirm-data.scss';

const validationSchema = Yup.object({
  masterName: Yup.string(),
  masterSurname: Yup.string(),
  middleName: Yup.string(),
  nationality: Yup.string(),
  telephoneNumber: Yup.string().matches(/^[+\d]([0-9]+\s)*[0-9]+$/, 'Invalid phone number'),
});

function ConfirmData() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [formDisabled, setFormDisabled] = useState(true);
  const formRef = useRef();
  const { formContextRef } = useContext(FormRefContext);

  const { crewList } = useSelector(selectCrew);
  let master = crewList?.find((object) => object.rank === 'Master');
  const masterName = master?.name;
  const masterSurname = master?.surname;
  const masterNationality = master?.nationality;
  const telephoneNumber = master?.telephoneNumber;

  const initialValues = {
    masterName,
    masterSurname,
    masterNationality,
    telephoneNumber,
  };
  const [telephoneNumberValue, setTelephoneNumberValue] = useState(initialValues.telephoneNumber);

  useEffect(() => {
    formContextRef.current = formRef.current;
  }, [formContextRef, formRef, masterName, masterSurname, masterNationality, formDisabled]);

  // Custom hook to sync form values with the telephoneNumberValue state
  function SyncTelephoneNumberValue() {
    const { values } = useFormikContext();

    useEffect(() => {
      setTelephoneNumberValue(values.telephoneNumber);
    }, [values.telephoneNumber]);

    return null;
  }

  const handleSubmit = async (values) => {
    const changedValues = diff(initialValues, values);
    if (Object.keys(changedValues).length > 0) {
      let objIndex = crewList?.findIndex((cl) => cl?.rank === 'Master');

      if (objIndex !== -1) {
        const originalObj = crewList[objIndex];

        const updatedObj = {
          ...originalObj,
          name: values.masterName,
          surname: values.masterSurname,
          nationality: values.masterNationality,
          telephoneNumber: values.telephoneNumber,
        };

        const filteredUpdatedObj = Object.fromEntries(
          Object.entries(updatedObj).filter(([key, value]) => !(key === 'IDNature' && isEmpty(originalObj[key])))
        );

        const updatedCrewList = [...crewList.slice(0, objIndex), filteredUpdatedObj, ...crewList.slice(objIndex + 1)];

        dispatch(
          updateCrew({
            uid: params.uid,
            data: { crewList: updatedCrewList },
          })
        );
      }
    }
    setFormDisabled(true);
  };

  return (
    <div className='crew-master-data'>
      <div className='crew-master-data__header'>
        <PageHeader title={'Crew'} subtitle={`Please confirm that your name and nationality is correct`} />
        {formDisabled && (
          <Button onClick={() => setFormDisabled(false)} theme='light' iconLeft={editIcon}>
            Edit
          </Button>
        )}
        {!formDisabled && (
          <div className='save-and-discard-btns'>
            <DiscardButton
              onClick={() => {
                formRef.current.resetForm();
                setFormDisabled(true);
              }}>
              Discard
            </DiscardButton>
            <Button
              onClick={() => {
                formRef.current.submitForm();
              }}
              theme='light'
              iconLeft={editIcon}>
              Save
            </Button>
          </div>
        )}
      </div>
      <Formik
        innerRef={formRef}
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}>
        <Form className='crew-master-data-form'>
          <div className='crew-master-data-form__row'>
            <Input disabled={formDisabled} name='masterName' placeholder='Given Names' label='Given Names' />
            <Input disabled={formDisabled} name='masterSurname' placeholder='Family Name' label='Family Name' />
            <MultiSelect
              disabled={formDisabled}
              name='masterNationality'
              options={countryListAllIsoData}
              isMulti={false}
              label='Nationality'
              placeholder='Choose'
            />
          </div>
          <div className='crew-master-data-form__row'>
            <Input
              disabled={formDisabled}
              name='telephoneNumber'
              placeholder='Telephone number'
              label='Telephone number *'
              hasError={isEmpty(telephoneNumberValue)}
              errorMessage={MandatoryFieldMessages.RequiredField}
            />
          </div>
          <SyncTelephoneNumberValue />
          <SkipAndNextButtons
            next={`/${params.uid}/crew/number`}
            back={`/${params.uid}/inspections`}
            onClick={() => navigate(`/${params.uid}/crew/number`)}
          />
        </Form>
      </Formik>
    </div>
  );
}

export default ConfirmData;
