export function areArraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
        return false;
    }

    for (let i = 0; i < arr1.length; i++) {
        const obj1 = arr1[i];
        const obj2 = arr2[i];

        if (!isObjectEqual(obj1, obj2)) {
            return false;
        }
    }

    return true;
}

function isObjectEqual(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }

    return true;
}

export function howManyDecimalDigits(number){
    const numberAsString = number.toString();
    const decimalPoint = numberAsString.indexOf('.');

    let decimalDigits = 0;
    if (decimalPoint !== -1) {
        decimalDigits = numberAsString.length - decimalPoint - 1;
    }

    return decimalDigits
}

export function hasMoreThanNDecimalsDigits(number, n) {
    const numberAsString = number.toString();

    const decimalPoint = numberAsString.indexOf('.');

    return !(decimalPoint === -1 || numberAsString.length - decimalPoint - 1 <= n);

}

export function isNumeric(x) {
    return !isNaN(x) && !isNaN(parseFloat(x)) && (typeof x !== "object") &&
        (x !== Number.POSITIVE_INFINITY) && (x !== Number.NEGATIVE_INFINITY);
}

export function isDateBetween(dateToCheck, fromDate, toDate) {
    if (dateToCheck === null || fromDate === null || toDate === null) {
        return true;
    }

    const checkDate = new Date(dateToCheck).getTime();
    const startDate = new Date(fromDate).getTime();
    const endDate = new Date(toDate).getTime();

    return checkDate >= startDate && checkDate <= endDate;
}

