import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { STATUS_OPEN_TO_AGENT } from '../../../../../config/portCall';
import Button from '../../../../layout/components/Button';
import Modal from '../../../../layout/components/Modal';
import PageHeader from '../../../../layout/components/PageHeader';
import { selectGeneralData, updatePortCallStatus } from '../../../slices/portCallSlice';
import {
  generatePreArrivalDocuments,
  getRequiredDocuments,
  selectDocuments,
} from '../../documents/slices/documentsSlice';
import '../styles/instructions.scss';

const SendFormModal = function (props) {
  const params = useParams();
  const dispatch = useDispatch();
  const instructionsContainerRef = useRef(null);
  const generalPortcallData = useSelector(selectGeneralData);
  const { instructionsText, status } = generalPortcallData;
  const portCallDocuments = useSelector(selectDocuments)?.documents;
  const instructionsApiDocs = useSelector((state) => state.documents.portInstructions);
  let instructionsDocuments = portCallDocuments?.instructionsDocuments;
  instructionsDocuments =
    instructionsDocuments && instructionsDocuments.length > 0 ? instructionsDocuments : instructionsApiDocs;
  const isInstructionOpened = useSelector((state) => state.documents.isInstructionOpened);
  useEffect(() => {
    dispatch(getRequiredDocuments({ uid: params.uid }));
  }, [params.uid, dispatch]);

  useEffect(() => {
    if (status === STATUS_OPEN_TO_AGENT) props.setIsConfirmationModalOpen(true);
  }, [status, props]);

  const isThereInstructionsDocument = () => instructionsDocuments && instructionsDocuments.length > 0;
  const isThereInstructionsText = () => instructionsText && instructionsText.length > 0;
  const areThereNoInstructions = () => !isThereInstructionsText() && !isThereInstructionsDocument();

  const disableSendButton = () => {
    if (areThereNoInstructions()) return false;
    else return !isInstructionOpened;
  };

  const onSendFormClick = useCallback(() => {
    const payload = {
      uid: params.uid,
      newStatus: STATUS_OPEN_TO_AGENT,
    };
    props.setIsInstructionsModalOpen(false);
    dispatch(generatePreArrivalDocuments({ uid: params.uid }));
    dispatch(updatePortCallStatus(payload));
  }, [params.uid, props, dispatch]);

  return (
    <Modal
      className={`port-call-instructions-modal`}
      hideCloseBtn={true}
      showModal={props.isInstructionsModalOpen}
      onCloseModal={() => {
        props.setIsInstructionsModalOpen(false);
      }}>
      <div className='port-call-instructions-modal__header'>
        <PageHeader
          title={'Are you sure you want to send this form?'}
          subtitle={
            disableSendButton()
              ? 'Review instruction document before forwarding it to the agent.'
              : props.hasMissingFields
              ? 'There are still missing information to be completed.'
              : ''
          }
        />
      </div>
      <div className='port-call-instructions-modal__footer'>
        <Button
          className='generate-form-btn light-button'
          onClick={() => {
            props.setIsInstructionsModalOpen(false);
          }}>
          Back
        </Button>
        <Button disabled={disableSendButton()} className='generate-form-btn' onClick={onSendFormClick}>
          Send anyway
        </Button>
      </div>
    </Modal>
  );
};

export default SendFormModal;
