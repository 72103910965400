import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MandatoryFieldRules } from '../../../../captains-form/validations/MandatoryFieldRules';
import { NotificationAlert } from '../../../../global/alertModal/alertModal';
import Page from '../../../../layout/components/Page';
import PageHeader from '../../../../layout/components/PageHeader';
import ProgressBar from '../../../../layout/components/ProgressBar';
import {
  generatePreArrivalDocuments,
  generatePreDepartureDocuments,
  getPortInstructions,
  getRequiredDocuments,
  selectDocuments,
} from '../../documents/slices/documentsSlice';
import { selectGeneralData, updateMissingFields } from '../../../slices/portCallSlice';
import SkipAndNextButtons from '../../port-call-data/components/SkipAndNextButtons';
import WarningIcon from '../styles/images/warning-icon.svg';
import '../styles/summary.scss';
import SummaryDocumentsBlock from './SummaryDocumentsBlock';
import { PortInstructionItem } from './PortInstructionItem';

const TERMINAL_SUBCATEGORY = 'Terminals';
const PILOTAGE_SUBCATEGORY = 'Pilotage';
const HEALTH_SUBCATEGORY = 'Health';

function Summary() {
  const params = useParams();
  const dispatch = useDispatch();
  const portCallDocuments = useSelector(selectDocuments)?.documents;
  const instructionsApiDocs = useSelector((state) => state.documents.portInstructions);
  const [showLoadingAllDocuments, setShowLoadingAllDocuments] = useState(false);
  const [showLoadingSpecificDocument, setShowLoadingSpecificDocument] = useState(false);
  const [missingFieldsSummary, setMissingFieldsSummary] = useState([]);
  const [instructionCallDispatched, setInstructionCallDispatched] = useState(false);
  const generalPortcallData = useSelector(selectGeneralData);
  let instructions = portCallDocuments?.instructionsDocuments?.map((_) =>
    _.name ? _ : { ..._, name: 'Instruction Document' }
  );
  instructions = instructions && instructions.length > 0 ? instructions : instructionsApiDocs;
  useEffect(() => {
    setShowLoadingAllDocuments(true);
    dispatch(generatePreArrivalDocuments({ uid: params.uid })).finally(() => {
      dispatch(generatePreDepartureDocuments({ uid: params.uid })).finally(() => {
        dispatch(getRequiredDocuments({ uid: params.uid })).finally(() => {
          setShowLoadingAllDocuments(false);
        });
      });
    });

    new MandatoryFieldRules().getWarningSummaries(params.uid).then((summaries) => setMissingFieldsSummary(summaries));
  }, [params.uid, dispatch]);

  useEffect(() => {
    if (
      generalPortcallData?.unlocode &&
      generalPortcallData?.agent?.uid &&
      !instructionCallDispatched &&
      (!instructions || instructions?.length == 0)
    ) {
      dispatch(
        getPortInstructions({
          portUnloCode: generalPortcallData?.unlocode,
          agentCompanyUid: generalPortcallData?.agent?.uid,
        })
      );
      setInstructionCallDispatched(true);
    }
  }, [generalPortcallData, dispatch]);

  useEffect(() => {
    dispatch(updateMissingFields({ isThereMissingFields: missingFieldsSummary.length > 0 }));
  }, [missingFieldsSummary, dispatch]);

  return (
    <Page>
      <div className='summary'>
        <NotificationAlert
          id={'Alert-Information'}
          alertType={'information'}
          isOpened={showLoadingAllDocuments || showLoadingSpecificDocument}
          setIsOpened={(open) => {
            setShowLoadingAllDocuments(open);
            setShowLoadingSpecificDocument(open);
          }}
          title={'Document generation in progress'}
          message={'Your documents will be ready in a few seconds.'}
          showCloseButton={false}
        />
        <ProgressBar percentage={'100'} pageNumber={1} totalPages={'1'} />
        {missingFieldsSummary && missingFieldsSummary.length > 0 && (
          <div className='missing-fields-card'>
            <img style={{ height: '24px', width: '24px' }} src={WarningIcon} />
            <div>
              <h3>Complete the sections below before sending the form to the agent:</h3>
              <ul>
                {missingFieldsSummary.map((field, index) => (
                  <li key={index}>{field}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
        <PageHeader
          title={'Summary'}
          subtitle={`If you see missing or incorrect information, please correct it going to the section in the left side menu.`}
        />
        <PortInstructionItem
          title={'Port Call Instructions'}
          subtitle={'Read the port call instructions provided by your agent before submitting the form.'}
          instructionsText={generalPortcallData?.instructionsText}
          emptyDocumentsMessage={'There are no Port Call Instructions.'}
          documents={instructions}
        />
        <SummaryDocumentsBlock
          title={'General pre-arrival documents'}
          emptyDocumentsMessage={'There are no general pre-arrival documents.'}
          documents={portCallDocuments?.preArrivalDocuments}
          uploadSlice={generatePreArrivalDocuments}
          updateDocuments={showLoadingAllDocuments}
          setShowLoadingAlert={setShowLoadingSpecificDocument}
        />
        <SummaryDocumentsBlock
          title={'General pre-departure documents'}
          emptyDocumentsMessage={'There are no general pre-arrival documents.'}
          documents={portCallDocuments?.preDepartureDocuments}
          uploadSlice={generatePreDepartureDocuments}
          updateDocuments={showLoadingAllDocuments}
          setShowLoadingAlert={setShowLoadingSpecificDocument}
        />
        <SummaryDocumentsBlock
          title={'Local formalities - cargo documents'}
          emptyDocumentsMessage={'There are no cargo documents.'}
          documents={portCallDocuments?.cargoDocuments}
          updateDocuments={showLoadingAllDocuments}
          setShowLoadingAlert={setShowLoadingSpecificDocument}
        />
        {portCallDocuments?.localFormalities && portCallDocuments?.localFormalities.length > 0 && (
          <h3 className='document-type-title'>{'Local formalities - safety documents'}</h3>
        )}
        <SummaryDocumentsBlock
          subtitle={'Terminal documents'}
          emptyDocumentsMessage={'No documents requested.'}
          documents={portCallDocuments?.localFormalities}
          updateDocuments={showLoadingAllDocuments}
          setShowLoadingAlert={setShowLoadingSpecificDocument}
          filter={TERMINAL_SUBCATEGORY}
        />
        <SummaryDocumentsBlock
          subtitle={'Pilotage documents'}
          emptyDocumentsMessage={'No documents requested.'}
          documents={portCallDocuments?.localFormalities}
          updateDocuments={showLoadingAllDocuments}
          setShowLoadingAlert={setShowLoadingSpecificDocument}
          filter={PILOTAGE_SUBCATEGORY}
        />
        <SummaryDocumentsBlock
          subtitle={'Health documents'}
          emptyDocumentsMessage={'No documents requested.'}
          documents={portCallDocuments?.localFormalities}
          updateDocuments={showLoadingAllDocuments}
          setShowLoadingAlert={setShowLoadingSpecificDocument}
          filter={HEALTH_SUBCATEGORY}
        />

        <SummaryDocumentsBlock
          title={'Ship certificates requested by agent'}
          emptyDocumentsMessage={'There are no ship certificates.'}
          updateDocuments={showLoadingAllDocuments}
          setShowLoadingAlert={setShowLoadingSpecificDocument}
          documents={portCallDocuments?.requestedCertificates}
        />
      </div>
      <SkipAndNextButtons back={`/${params.uid}/general-declaration`} noNext={true} />
    </Page>
  );
}

export default Summary;
